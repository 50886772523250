<template>
  <div></div>
</template>

<script>
export default {
  name: "HomeView",
  mounted() {
    //  Redirect to ticketme.app
    window.location.href = process.env.VUE_APP_REDIRECT;
  },
};
</script>
